import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL + '/api'

// Get user from localStorage
const session = JSON.parse(localStorage.getItem('session'))

const initialState = {
  session: session ? session : null,
  isAuth: session ? true : false,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// Login user
export const login = createAsyncThunk(
  'auth/login',
  async (userData, thunkAPI) => {
    try {
      const { data } = await axios.post(API_URL + '/user/login', userData)

      console.log(data)

      if (data) {
        localStorage.setItem('session', JSON.stringify(data.body))
      }

      return data.body
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const logout = createAsyncThunk('auth/logout', async () => {
  localStorage.removeItem('session')

  if (window.location.hostname !== 'localhost') {
    location.href = 'https://login.yummsprint.com/logout'
  }
})

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
    },

    encyptLogin: (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.isError = false
      state.session = action.payload
      state.isAuth = true

      localStorage.setItem('session', JSON.stringify(action.payload))
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.isError = false
        state.session = action.payload
        state.isAuth = true
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.role = ''
      })
      .addCase(logout.fulfilled, (state) => {
        state.session = null
        state.isAuth = false
      })
  },
})

export const { reset, encyptLogin } = authSlice.actions
export default authSlice.reducer
