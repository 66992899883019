import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Layout from './layout/Layout'
import RequireAuth from './utility/RequireAuth'

import Login from './pages/Login'
import Home from './pages/Home'
import Order from './pages/Order'
import EditOrder from './pages/EditOrder'
import { useEffect } from 'react'

const App = () => {
  useEffect(() => {
    const handlePageShow = (event) => {
      if (event.persisted) {
        window.location.reload()
      }
    }

    const handleVisibilityChange = () => {
      if (!document.hidden) {
        window.location.reload()
      }
    }

    window.addEventListener('pageshow', handlePageShow)
    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      window.removeEventListener('pageshow', handlePageShow)
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route path="/" element={<RequireAuth />}>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/order/edit" element={<EditOrder />} />
            <Route path="/order/create/:id" element={<Order />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
